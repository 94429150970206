import { stringify } from 'query-string'
import { mapKeys, snakeCase } from 'lodash'

function removeSlashes(string) {
  const slashMatchRegex = /\//g
  return string.replace(slashMatchRegex, '')
}

function urlFromArray(urlSegmentArray) {
  const noSlashArray = urlSegmentArray.map(segment => removeSlashes(segment))
  return '/' + noSlashArray.join('/')
}

function buildUrl({ base, query = {} }) {
  const baseUrl = Array.isArray(base) ? urlFromArray(base) : base
  const snakeCasedQuery = mapKeys(query, (val, key) => snakeCase(key))
  const paramString = stringify(snakeCasedQuery)
  return paramString ? `${baseUrl}?${paramString}` : baseUrl
}

export default buildUrl
