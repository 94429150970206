import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { connectParams, onMount, waitFor, connectQuery } from 'lp-hoc'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
// import { LADDER_MAP } from 'config'
// import { displayLevelNumber } from 'utils'
import * as actions from '../actions'
import { Card, ListGroup, Button, Accordion } from 'react-bootstrap'
import { sortBy } from 'lodash'

const propTypes = {
  ladderLevelAssignments: PropTypes.object,
  ladder: PropTypes.string,
  level: PropTypes.string,
  // currentLadderLevel: PropTypes.object,
  // tableName: PropTypes.string.isRequired,
}

const defaultProps = {}

function LadderLevelShow({
  level,
  ladder,
  ladderLevelAssignments,
  // currentLadderLevel: {
  //   fields,
  //   fields: { externalTitle },
  //   level,
  // },
  // tableName,
}) {
  // const { name } = LADDER_MAP[tableName]
  return (
    <div>
      {/*<Card>
        <Card.Body>
          <Card.Title style={{ textAlign: 'center' }}>
            <b>
              {name} Level {level}
            </b>
          </Card.Title>
          <Card.Text>
            <p>Title: {externalTitle}</p>
            <p>Duration (months): {fields['duration(Mo.)']}</p>
          </Card.Text>
        </Card.Body>
      </Card>*/}

      <br />

      <Card>
        <Card.Body>
          <Card.Title style={{ textAlign: 'center' }}>
            <b>
              {ladder} - Level - {level}
            </b>
          </Card.Title>
          <Card.Text>
            <b>Pillars & Skills</b>
          </Card.Text>
          <Card.Text>
            <Accordion defaultActiveKey="0">
              {sortBy(Object.keys(ladderLevelAssignments)).map((pillarName) => {
                return (
                  <Card key={pillarName}>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={pillarName}
                      >
                        <b>{pillarName}</b>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={pillarName}>
                      <ListGroup>
                        {sortBy(
                          ladderLevelAssignments[pillarName],
                          'category'
                        ).map((assign) => {
                          return (
                            <ListGroup.Item key={assign.id}>
                              <div>
                                <p>
                                  <b>Category:</b> {assign.category}
                                </p>
                                <p>
                                  <b>Skill:</b> {assign.skill.fields.name}
                                </p>
                                {assign.skill.fields.notes && (
                                  <p>
                                    <b>Notes:</b> {assign.skill.fields.notes}
                                  </p>
                                )}
                                <p>
                                  <b>Skill Level:</b>{' '}
                                  {assign.skillLevel.fields.name}
                                </p>
                              </div>
                            </ListGroup.Item>
                          )
                        })}
                      </ListGroup>
                    </Accordion.Collapse>
                  </Card>
                )
              })}
            </Accordion>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

LadderLevelShow.propTypes = propTypes

LadderLevelShow.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assignments: selectors.assignments(state),
    currentLadderLevel: selectors.currentLadderLevel(state),
    projectRoles: selectors.projectRoles(state),
    skills: selectors.skills(state),
    skillLevels: selectors.skillLevels(state),
    ladderLevelAssignments: selectors.ladderLevelAssignments(state),
  }
}

const mapDispatchToProps = {
  fetchAssignments: apiActions.fetchAssignments,
  fetchLadderLevel: apiActions.fetchLadderLevel,
  fetchLevels: apiActions.fetchLevels,
  fetchProjectRoles: apiActions.fetchProjectRoles,
  fetchSkills: apiActions.fetchSkills,
  fetchSkillLevels: apiActions.fetchSkillLevels,
  setLadderLevel: actions.setLadderLevel,
}

function onComponentDidMount({
  fetchAssignments,
  // fetchLadderLevel,
  // fetchLevels,
  // ladderLevelId,
  fetchSkills,
  fetchSkillLevels,
  // tableName,
  level,
  ladder,
}) {
  // fetchLadderLevel(tableName, ladderLevelId)
  // fetchLevels()
  fetchSkillLevels()
  fetchSkills()
  fetchAssignments({ level, ladder })
}

export default compose(
  connectParams(['tableName', 'ladderLevelId']),
  connectQuery(['level', 'ladder']),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(onComponentDidMount),
  waitFor(['ladderLevelAssignments'])
)(LadderLevelShow)
