import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { connectParams, onMount, waitFor } from 'lp-hoc'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { LADDER_MAP, LADDERS_ROUTE, LEVELS_ROUTE } from 'config'
import { Link } from 'react-router-dom'
import { buildUrl } from 'utils'
import { Card, ListGroup } from 'react-bootstrap'

const propTypes = {
  sortedTitles: PropTypes.array,
  tableName: PropTypes.string.isRequired,
  ladderPillars: PropTypes.array,
}

const defaultProps = {}

function LadderShow({ tableName, sortedTitles, ladderPillars }) {
  const { description, name, overviewHref } = LADDER_MAP[tableName]
  return (
    <div>
      <Card>
        <Card.Body>
          <a href={overviewHref} target="_blank" rel="noopener noreferrer">
            <Card.Title style={{ textAlign: 'center' }}>
              <b>{name} Ladder</b>
            </Card.Title>
          </a>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
      </Card>

      <br />

      <Card>
        <Card.Body>
          <Card.Title>
            <b>Pillars</b>
          </Card.Title>
          <ul>
            {ladderPillars.map((ladderPillar, idx) => (
              <li key={idx}>{ladderPillar}</li>
            ))}
          </ul>
        </Card.Body>
        <Card.Body>
          <Card.Title>
            <b>Levels</b>
          </Card.Title>
          <ListGroup>
            {sortedTitles.map((title) => (
              <ListGroup.Item
                as={Link}
                key={title.id}
                to={buildUrl({
                  base: [LADDERS_ROUTE, tableName, LEVELS_ROUTE, title.id],
                  query: { level: title.levelNumber, ladder: 'Dev' },
                })}
              >
                Level {title.levelNumber} - {title.fields.externalTitle}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </div>
  )
}

LadderShow.propTypes = propTypes

LadderShow.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assignments: selectors.assignments(state),
    levels: selectors.levels(state),
    ladderLevels: selectors.ladderLevels(state),
    ladderPillars: selectors.ladderPillars(state),
    projectRoles: selectors.projectRoles(state),
    sortedTitles: selectors.sortedTitles(state),
  }
}

const mapDispatchToProps = {
  fetchSkills: apiActions.fetchSkills,
  fetchTitles: apiActions.fetchTitles,
}

function onComponentDidMount({ fetchSkills, tableName, fetchTitles }) {
  fetchTitles(tableName)
  fetchSkills()
}

export default compose(
  connectParams('tableName'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  onMount(onComponentDidMount),
  waitFor(['sortedTitles', 'ladderPillars'])
)(LadderShow)
