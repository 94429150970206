import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
// import { pure } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import logo from 'images/logo.svg'

const propTypes = {
  history: PropTypes.object,
}

const defaultProps = {}

function Header({ history }) {
  const backAvailable = history.length > 1

  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <img className="logo" src={logo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/ladders">Ladders</Nav.Link>
          {backAvailable && (
            <Nav.Link onClick={() => history.goBack()}>&lt; Back</Nav.Link>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

export default withRouter(Header)
