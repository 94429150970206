import React from 'react'
import { Link } from 'react-router-dom'
import { LADDER_MAP } from 'config'
import { ListGroup, Card } from 'react-bootstrap'

const propTypes = {}

const defaultProps = {}

function Ladders() {
  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title style={{ textAlign: 'center' }}>
            <b>Overview</b>
          </Card.Title>
          <Card.Text>
            The purpose of each Functional Group's <b>Ladder</b> is to create
            transparency around expectations and the growth path for employees
            at LPL.
          </Card.Text>
          <Card.Text>
            Each Ladder was designed via input from the functional group team
            and the Leadership team. Its structure is similar across functional
            groups. The Ladder is unique to LaunchPad Lab. Especially the
            Company and Professional areas, which map to Our Seven Constants
            plus skills needed to be successful in a small consultancy. With
            that said, the skills are transferable. The functional group areas
            have been informed by widely accepted core skills to each functional
            group.
          </Card.Text>
        </Card.Body>
      </Card>

      <br />

      <Card>
        <Card.Body>
          <Card.Title style={{ textAlign: 'center' }}>
            <b>Functional Group</b>
          </Card.Title>
          <Card.Text>
            <ListGroup>
              {Object.keys(LADDER_MAP).map((ladderKey) => {
                const isPublished = LADDER_MAP[ladderKey].published
                return (
                  <ListGroup.Item
                    as={isPublished ? Link : Card.Text}
                    key={ladderKey}
                    to={LADDER_MAP[ladderKey].route}
                  >
                    {isPublished && <span>&#9989;</span>}
                    {!isPublished && <span>&#x1F6A7;</span>}
                    {LADDER_MAP[ladderKey].name}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

Ladders.propTypes = propTypes
Ladders.defaultProps = defaultProps

export default Ladders
