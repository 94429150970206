import { buildUrl } from 'utils'
// App-wide config (routes, env vars) goes here.

export function isProduction() {
  return process.env.NODE_ENV === 'production'
}

export const DEV_TABLE_KEY = process.env.DEV_TABLE_KEY
export const DESIGN_TABLE_KEY = process.env.DESIGN_TABLE_KEY
export const PRODUCT_TABLE_KEY = process.env.PRODUCT_TABLE_KEY

export const LADDERS_ROUTE = '/ladders'
export const LEVELS_ROUTE = '/levels'

export const LADDER_MAP = {
  [DEV_TABLE_KEY]: {
    tableName: DEV_TABLE_KEY,
    route: buildUrl({ base: [LADDERS_ROUTE, DEV_TABLE_KEY] }),
    name: 'Development',
    published: true,
    overviewHref: 'https://launchpadlab.com/blog/the-art-of-growth/',
    description: '',
  },
  [DESIGN_TABLE_KEY]: {
    tableName: DESIGN_TABLE_KEY,
    route: buildUrl({ base: [LADDERS_ROUTE, DESIGN_TABLE_KEY] }),
    name: 'Design',
    description:
      'In pellentesque porta condimentum. Duis convallis ante at nisl pretium viverra. Mauris luctus dolor est, vitae feugiat erat egestas eu. Mauris ultricies lobortis mollis. Phasellus a elit ac turpis pulvinar viverra eget quis nibh. Pellentesque dolor tortor, consectetur non elit vel, cursus consectetur est. Cras pharetra sem ac scelerisque consectetur.',
  },
  [PRODUCT_TABLE_KEY]: {
    tableName: PRODUCT_TABLE_KEY,
    route: buildUrl({ base: [LADDERS_ROUTE, PRODUCT_TABLE_KEY] }),
    name: 'Product',
    description:
      'Curabitur gravida lorem ac ullamcorper scelerisque. Phasellus interdum dignissim iaculis. Etiam ut consectetur nisl. Nulla sit amet ipsum velit. Morbi at libero at dolor commodo lobortis. Morbi sed scelerisque erat. Nam ultrices aliquet neque, a tempor odio.',
  },
}
