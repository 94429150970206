import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState, setState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as actions from './actions'
import { createSelector } from 'reselect'
import { displayLevelNumber } from 'utils'
import { groupBy, map, first, uniq, sortBy } from 'lodash'

const reducerKey = 'ladder'
const slice = 'root.ladder'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchAssignments]: setOnSuccess('assignments'),
    [apiActions.fetchLadder]: setOnSuccess('ladder'),
    [apiActions.fetchLadderLevel]: setOnSuccess('ladderLevel'),
    [apiActions.fetchLevel]: setOnSuccess('level'),
    [apiActions.fetchLevels]: setOnSuccess('levels'),
    [apiActions.fetchProjectRoles]: setOnSuccess('projectRoles'),
    [apiActions.fetchSkills]: setOnSuccess('skills'),
    [apiActions.fetchSkillLevels]: setOnSuccess('skillLevels'),
    [apiActions.fetchTitles]: setOnSuccess('titles'),
    [actions.setLadderLevel]: setState('ladderLevel'),
    [actions.clearLevel]: unsetState('level'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  assignments: select('assignments'),
  ladder: select('ladder'),
  ladderLevel: select('ladderLevel'),
  ladderAssignments: select('ladderAssignments'),
  ladderLevelAssignments: select('ladderLevelAssignments'),
  ladderPillars: select('ladderPillars'),
  level: select('level'),
  levels: select('levels'),
  currentLadderLevel: select('currentLadderLevel'),
  projectRoles: select('projectRoles'),
  skills: select('skills'),
  skillLevels: select('skillLevels'),
  titles: select('titles'),
}

function findLevelForLadderLevel(ladderLevel, levels) {
  return levels.find((level) => {
    return ladderLevel === level.id
  })
}

function findExternalTitleForLadderLevel(ladderLevel, titles) {
  return titles.find((title) => {
    return title.fields.level.includes(ladderLevel)
  })
}

selectors.currentLadderLevel = createSelector(
  [selectors.ladderLevel, selectors.levels],
  function(ladderLevel, levels) {
    if (!ladderLevel || !levels) return null
    ladderLevel.level = findLevelForLadderLevel(ladderLevel, levels)
    return ladderLevel
  }
)

selectors.sortedTitles = createSelector(
  [selectors.titles],
  function(titles) {
    if (!titles) return []

    return titles
      .map((title) => {
        title.levelNumber = parseInt(title.fields.levelNumber[0])
        return title
      })
      .sort((l1, l2) => {
        return l1.levelNumber - l2.levelNumber
      })
  }
)

selectors.ladderLevels = createSelector(
  [selectors.ladder, selectors.levels, selectors.titles],
  function(ladders, levels, titles) {
    if (!ladders || !levels || !titles) return []
    const ladder = first(ladders)
    return ladder.fields.levels
      .map((ladderLevel) => {
        const level = findLevelForLadderLevel(ladderLevel, levels)
        const title = findExternalTitleForLadderLevel(ladderLevel, titles)
        level.externalTitle = title.fields.externalTitle
        return level
      })
      .sort((l1, l2) => {
        return displayLevelNumber(l1) - displayLevelNumber(l2)
      })
  }
)

selectors.ladderAssignments = createSelector(
  [selectors.ladderLevels, selectors.assignments],
  function(ladderLevels, assignments) {
    if (!assignments) return []
    const ladderLevelsIds = map(ladderLevels, 'level.id')
    return assignments
      .filter(
        (assign) => Object.keys(assign.fields).length && assign.fields.levels
      )
      .filter((assign) => {
        return assign.fields.levels.filter((aLevelId) =>
          ladderLevelsIds.includes(aLevelId)
        )
      })
  }
)

selectors.ladderPillars = createSelector(
  [selectors.skills],
  function(skills) {
    if (!skills) return []
    const pillars = skills.flatMap((skill) => skill.fields.pillar)
    return sortBy(uniq(pillars))
  }
)

selectors.ladderLevelAssignments = createSelector(
  [selectors.assignments, selectors.skills, selectors.skillLevels],
  function(assignments, skills, skillLevels) {
    if (!assignments || !skills || !skillLevels) return {}
    const filteredAssignments = assignments.map((assign) => {
      const skill = skills.find((skill) =>
        assign.fields.skills.includes(skill.id)
      )
      const skillLevel = skillLevels.find((skillLevel) =>
        assign.fields.skillLevels.includes(skillLevel.id)
      )
      const category = assign.fields['skill:Category'][0]
      return { ...assign, skill, skillLevel, category }
    })

    return groupBy(filteredAssignments, (assign) => {
      return assign.fields.pillar[0]
    })
  }
)

export { reducer, selectors, reducerKey }
