import { combineReducers } from 'redux'
import {
  reducer as laddersReducer,
  reducerKey as laddersReducerKey,
} from './ladders'
import {
  reducer as styleguideReducer,
  reducerKey as styleguideReducerKey,
} from './styleguide'

const reducerKey = 'root'

const reducer = combineReducers({
  [laddersReducerKey]: laddersReducer,
  [styleguideReducerKey]: styleguideReducer,
})

export { reducer, reducerKey }
