import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const propTypes = {
  to: PropTypes.string.isRequired,
}

const defaultProps = {}

function NavLink(props) {
  const active = window.location.pathname.includes(props.to)
  return <Link className={active ? 'selected' : ''} {...props} />
}

NavLink.propTypes = propTypes
NavLink.defaultProps = defaultProps

export default NavLink
